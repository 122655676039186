<!--
 * @Description: 录入
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-26 22:54:41
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/service/mods/add.vue
-->
<template>
  <div class="model-popup" @click="shows = false">
    <div class="model" @click.stop>
      <div class="model-text">手工录入代理商</div>
      <div class="model-content">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          size="medium"
          label-width="100px"
        >
          <el-col :span="24">
            <el-form-item label="姓名" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入姓名"
                style="width: 272px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="联系方式" prop="mobile">
              <el-input
                v-model="form.mobile"
                placeholder="请输入联系方式"
                style="width: 272px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="公司名称" prop="company">
              <el-input
                v-model="form.company"
                placeholder="请输入公司名称"
                style="width: 272px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="申请区域" prop="area">
              <el-input
                v-model="form.area"
                placeholder="请输入申请区域"
                style="width: 272px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remarks">
              <el-input
                type="textarea"
                v-model="form.remarks"
                placeholder="请输入备注"
                style="width: 272px"
                rows="3"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <div class="model-btn">
        <div class="btn confirm" @click="submitForm">确认</div>
        <div class="btn" @click="shows = false">取消</div>
      </div>
      <div class="model-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";

const URL = {
  manualEntry: "api/operation/agentLeads/manualEntry",
};
export default {
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "add",
    },
    data: {
      type: Object,
    },
  },

  data() {
    return {
      // 表单参数
      form: {
        name: "",
        mobile: "",
        company: "",
        area: "",
        remarks: "",
      },
      // 表单校验
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        company: [{ required: true, message: "请输入公司名", trigger: "blur" }],
        area: [{ required: true, message: "请输入申请区域", trigger: "blur" }],
        remarks: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  created() {},
  methods: {
    /** 提交按钮 */
    async submitForm() {
      try {
        await this.$refs["form"].validate();
        await HTTP({
          url: URL.manualEntry,
          method: "post",
          data: {
            ...this.form,
          },
        });
        this.$message.success("录入成功");
        this.$emit("update");
        this.shows = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.model-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  .model {
    width: 510px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-sub-text {
      font-size: 20px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #b4b4b4;
      line-height: 24px;
      text-align: center;
      margin-top: 14px;
    }
    &-content {
      width: 420px;
      overflow: hidden;
      margin: auto {
        top: 40px;
      }
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 18px 0 50px 0;
      .btn {
        width: 140px;
        height: 37px;
        background-color: #e0e4f6;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm {
        background-color: #4e6bef;
        color: #fff;
      }
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
